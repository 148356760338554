@charset "UTF-8";
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
#top .p-slider__img img {
  display: block;
  width: 100%;
  line-height: 0;
}

/*
ユーティリティ系おまとめファイル
*/
#top .p-slider {
  padding: 0;
  line-height: 0;
}
#top .p-slider__img img {
  height: 850px;
  object-fit: cover;
  font-family: "object-fit: cover;";
}
@media screen and (max-width: 768px) {
  #top .p-slider__img img {
    height: 800px;
  }
}
@media screen and (max-width: 568px) {
  #top .p-slider__img img {
    height: 100svh;
  }
}
#top .p-slider__container {
  position: relative;
}
#top #wrapper {
  padding: 0 0;
}
@media screen and (max-width: 1200px ) {
  #top #wrapper {
    padding: 60px 0 0;
  }
}